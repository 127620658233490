.kod-table-content {
  box-shadow: none !important;
  margin-top: 25px;

  .MuiToolbar-gutters {
    @apply p-0 pl-3 min-h-0 mb-2;

    div[class$="MUIDataTableToolbar-actions"] {
      .MuiButtonBase-root.MuiIconButton-root {
        border: 1px solid #e1e4ea !important;
        @apply rounded-md ml-1;

        svg {
          @apply w-5 h-5;
        }
      }
    }
  }

  .MuiTable-root {
    .MuiTableHead-root {
      tr {
        th {
          @apply py-0 bg-[#F5F7FA] text-xs border-0 h-8;

          &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }
    }
  }
}
