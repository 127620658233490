.kod-loading-bg {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(231 245 255 / 40%);
}

.kod-loading-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 140px;
  width: 300px;
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px #0a0d1408;
}

.kod-loading-text {
  font-weight: 600;
}

.loader {
  position: relative;
  display: flex;
  margin: 10px 0 30px;
}
.loader:before,
.loader:after {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  margin: 0 5px;
  border-radius: 50%;
  color: #3b82f6;
  background: currentColor;
  box-shadow: 50px 0, -50px 0;
  animation: left 1s infinite ease-in-out;
}
.loader:after {
  color: #3b82f6;
  animation: right 1.1s infinite ease-in-out;
}

@keyframes right {
  0%,
  100% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
}

@keyframes left {
  0%,
  100% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
}
