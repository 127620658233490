.app-header {
  @apply flex items-center justify-between h-[88px] space-x-3 border-b-[#E1E4EA] border-b;
  @apply px-4 lg:px-0;

  .app-header-welcome {
    @apply flex items-center h-[88px] space-x-3;

    .app-header-welcome-logo {
      @apply w-10 h-10 bg-blue-200 rounded-full;

      .mobile-button {
        @apply lg:hidden;
      }
    }

    .app-header-welcome-content {
      @apply flex flex-col;

      .profile-name {
        @apply text-[13px] font-medium;
      }
      .app-header-welcome-text {
        @apply text-[11px] font-normal;
      }
    }
  }

  .app-shortcuts {
    @apply flex items-center space-x-2;

    .app-shortcuts-item {
      @apply flex rounded-[10px] space-x-1 py-2 px-4 items-center justify-center;
      box-shadow: 0px 1px 2px 0px #0a0d1408;
      border: 1px solid var(--stroke-soft-200, #e1e4ea);

      &.showSite {
        background: var(--primary-base, #335cff);
        color: #ffffff;
        border: 0px;
      }

      &.signOut {
        background: var(--primary-base, #ff3333);
        color: #ffffff;
        border: 0px;
        padding: 5px 10px 11px;
        min-width: auto;

        .app-shortcuts-item-icon {
          svg {
            @apply w-5 h-5;
          }
        }
      }

      .app-shortcuts-item-icon {
        @apply w-5 h-5;

        svg {
          @apply w-4 h-4;
        }
      }

      .app-shortcuts-item-title {
        @apply text-[13px] font-medium hidden lg:block;
      }
    }
  }
}
