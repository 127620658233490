@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(./kod-table.scss);

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

body {
  font-family: "Inter", serif;
  font-size: 14px;
  color: #525866;
}

.app-top-header {
  @apply hidden  items-center justify-center z-10 pl-0 h-8 bg-slate-100 border-b border-b-[#E1E4EA] text-center text-xs text-slate-700;
  @apply lg:pl-72 lg:flex;
}

.app-body {
  @apply flex pl-0 lg:pl-72;

  .app-body-content {
    @apply flex flex-col w-full lg:mx-10;
  }
}

.dialog-container {
  #dialog-title {
    font-size: 15px;
    border-bottom: 1px solid #ededed;
  }

  &.no-padding {
    .MuiDialogContent-root {
      padding: 0 !important;
    }
  }

  .MuiDialogActions-root {
    padding: 12px 24px 12px 12px;
    background: rgb(246, 246, 246);
    border-top: 1px solid rgb(217, 217, 217);
  }

  .close-button {
    border: 1px solid rgba(97, 97, 97, 0.5);
    color: rgb(97, 97, 97);
    font-size: 12px;

    &:hover {
      text-decoration: none;
      background-color: rgba(97, 97, 97, 0.04);
      border: 1px solid rgb(97, 97, 97);
    }
  }

  .save-button {
    background: #3b82f6;
    box-shadow: none;
    font-size: 12px;

    &:hover {
      text-decoration: none;
      box-shadow: none;
      background: #3378e6;
    }
  }

  .MuiFormControl-root {
    .MuiInputLabel-root {
      font-size: 13px;
    }

    .MuiInputBase-root {
      font-size: 13px;
    }

    .MuiChip-deletable {
      font-size: 12px;
      height: 20px;
      margin: 1px 2px;
      line-height: 20px;
    }
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiOutlinedInput-root {
    padding-right: 40px !important;
  }
}

#react-confirm-alert {
  @apply absolute;
  z-index: 1301;

  .react-confirm-alert-overlay {
    background-color: #001e2659;
  }

  .react-confirm-alert {
    .react-confirm-alert-body {
      @apply p-0 overflow-hidden;
      border-radius: 4px;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      border-bottom: solid 7px #e74c3c;
      font-family: "Inter var";

      > h1 {
        font-size: 22px;
        line-height: 1.6;
        padding: 30px 24px 0;
        font-weight: bold;
        color: #001e26;
        text-align: center;
      }

      .react-confirm-alert-message {
        @apply p-6 text-[15px] text-center font-normal;
      }

      .react-confirm-alert-button-group {
        margin: 0px 0 20px;
        display: flex;
        justify-content: center;
        @apply space-x-2;

        > button {
          margin: 0;
          font-weight: 500;
          padding: 10px 15px;

          &.cancel-button {
            background-color: #e74c3c;
          }

          &.confirm-button {
            background-color: #34495e;
          }
        }
      }
    }
  }
}

.category-items {
  @apply flex;
}

.kod-price {
  input {
    text-align: right;
  }
}

#react-confirm-alert .react-confirm-alert .react-confirm-alert-body {
  font-family: "Inter", serif;
}

.custom-header {
  @apply flex justify-between items-start pl-5 lg:pl-0 my-5 flex-col lg:flex-row lg:items-center;
  @apply border-b pb-5 lg:border-b-0 lg:pb-0;

  .custom-header-title {
    @apply pb-4 lg:pb-0;

    .title {
      @apply text-base font-medium leading-6;
    }
    .description {
      @apply text-xs leading-5;
    }
  }

  .custom-header-buttons {
    @apply flex items-center space-x-3;

    .custom-header-button {
      @apply h-9 text-xs text-gray-500 rounded-lg capitalize px-4;
      box-shadow: 0px 1px 2px 0px #0a0d1408;
      border: 1px solid var(--stroke-soft-200, #e1e4ea);
    }

    .search-input {
      .MuiInputAdornment-root {
        @apply text-gray-400;
      }

      .MuiInputBase-input {
        @apply p-0 text-xs h-9;
      }
      fieldset {
        @apply rounded-lg;
        box-shadow: 0px 1px 2px 0px #0a0d1408;
        border: 1px solid var(--stroke-soft-200, #e1e4ea);
      }
    }
  }
}

.custom-tabs-content {
  @apply w-full;

  .custom-tabs {
    @apply w-full border-b-[#E1E4EA] border-b;

    .MuiTab-root {
      @apply capitalize p-0 px-5 min-h-12;
      font-family: "Inter", serif;

      .MuiSvgIcon-root {
        @apply w-4 h-4;
      }
    }
  }
}

.not-find-pagination {
  @apply flex items-center justify-center h-96 bg-gray-50 mt-4 rounded-md text-gray-400 text-lg;

  > span {
    @apply ml-2;
  }
}

.product-variant-dialog {
  .variant-table {
    border: 1px solid #ececec;
    border-radius: 6px;

    .MuiTableHead-root {
      .MuiTableCell-root {
        font-size: 13px;
        border-left: 1px solid #ececec;
        border-bottom: 1px solid #ececec;
        background-color: #fbfbfb;
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        padding: 0 16px;
        font-size: 13px;
        border-left: 1px solid #ececec;
        border-bottom: 1px solid #ececec;

        &.td-input {
          padding: 0;
          input {
            padding: 7px 10px;
            background-color: #fdfdfd;
            font-size: 12px;
          }
          fieldset {
            border: 0px;
          }
        }

        &:has(.variant-remove-button) {
          padding: 0;
        }

        .variant-remove-button {
          @apply w-full h-full p-1 rounded-none text-red-400;
          svg {
            @apply w-5;
          }
        }

        .input-barcode {
          .MuiInputBase-root {
            padding: 0;
            button {
              height: 33px;
              width: 33px;
              padding: 0 5px;
              border-right: 1px solid #ececec;
              border-radius: 0;

              &:hover {
                background-color: #fff;
              }
              svg {
                @apply w-4;
              }
            }
          }
        }
      }
    }
  }

  .variant-colors {
    @apply grid gap-3 grid-cols-5;

    .variant-color {
      @apply flex flex-col items-center h-60 text-xs text-gray-500 rounded-lg capitalize p-0;
      box-shadow: 0px 1px 2px 0px #0a0d1408;
      border: 1px solid var(--stroke-soft-200, #e1e4ea);

      &.selected {
        @apply border-blue-400 border-2 shadow-sm;
      }

      .variant-color-name {
        @apply flex items-center text-[10px] text-center justify-center h-10 w-full border-b;
      }

      .variant-color-image {
        @apply flex justify-center overflow-hidden items-center h-48 w-full;

        button {
          @apply w-full h-full text-gray-600;
          padding: 1px;
        }

        svg {
          @apply flex w-6 h-6;
        }
      }

      .variant-color-buttons {
        @apply flex justify-center items-center h-10 w-full border-t text-center;

        .variant-color-button {
          @apply text-[11px] text-gray-500 w-2/3 rounded-none capitalize;

          svg {
            @apply w-4 h-4 mr-1;
          }

          &.delete-button {
            min-width: auto;
            @apply w-1/3 border-l border-solid border-gray-200 text-red-400;

            svg {
              @apply w-5 h-5 mr-0;
            }
          }
        }
      }
    }
  }
}

.variant-size-dialog {
  .MuiDialogContent-root {
    padding: 0;
  }
  .variant-table {
    border: 1px solid #ececec;
    border-radius: 6px;

    .MuiTableHead-root {
      .MuiTableCell-root {
        font-size: 13px;
        border-left: 1px solid #ececec;
        border-bottom: 1px solid #ececec;
        background-color: #fbfbfb;
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        padding: 0 16px;
        font-size: 13px;
        border-left: 1px solid #ececec;
        border-bottom: 1px solid #ececec;

        &.td-input {
          padding: 0;
          input {
            padding: 7px 10px;
            background-color: #fdfdfd;
            font-size: 12px;
          }
          fieldset {
            border: 0px;
          }
        }

        &:has(.variant-remove-button) {
          padding: 0;
        }

        .variant-remove-button {
          @apply w-full h-full p-1 rounded-none text-red-400;
          svg {
            @apply w-5;
          }
        }

        .input-barcode {
          .MuiInputBase-root {
            padding: 0;
            button {
              height: 33px;
              width: 33px;
              padding: 0 5px;
              border-right: 1px solid #ececec;
              border-radius: 0;

              &:hover {
                background-color: #fff;
              }
              svg {
                @apply w-4;
              }
            }
          }
        }
      }
    }
  }
}

.MuiAutocomplete-popper {
  @apply text-xs;
}
