.app-menu {
  height: calc(100vh);
  @apply fixed left-0 top-0 bg-white border-r border-r-[#E1E4EA] min-w-72;
  @apply hidden lg:block;

  &.mobile-menu-open {
    display: block !important;
    width: 100%;
    z-index: 999;
    top: 91px;
  }

  .app-menu-header {
    @apply flex items-center h-[88px] border-b border-b-[#E1E4EA] space-x-3 mx-5;

    .app-menu-header-logo {
      @apply w-10 h-10 bg-blue-500 rounded-full;
    }

    .app-menu-header-content {
      @apply flex flex-col;

      .logo-text {
        @apply text-[14px] font-medium;
      }
      .logo-description {
        @apply text-[12px] font-normal;
      }
    }
  }

  .app-menu-items {
    height: calc(100vh - 176px);
    @apply space-y-2 overflow-x-auto p-5;

    .app-menu-item {
      @apply flex items-center justify-start w-full text-left text-sm text-[#525866] h-9;
      @apply font-medium rounded-lg space-x-2 py-0 pl-3 justify-between;

      &:hover {
        background-color: #f5f7fa;

        .app-menu-icon {
          svg {
            @apply text-[#335CFF] font-normal;
          }
        }
      }

      &.active {
        background-color: #f5f7fa;

        &::after {
          @apply absolute left-[-20px] w-1 h-6 bg-[#335CFF] rounded-tr rounded-br;
          content: " ";
        }

        .app-menu-content {
          .app-menu-icon {
            svg {
              color: #335cff;
            }
          }
        }
      }

      .app-menu-content {
        @apply flex items-center justify-start w-full space-x-2 text-sm text-[#525866] h-9;

        .app-menu-icon {
          @apply block -mt-[3px] w-5 h-5;

          svg {
            @apply w-full text-[#525866] h-full;
          }
        }
      }

      .menu-arrow-right {
        @apply w-5 h-5;
      }
    }
  }

  .app-menu-footer {
    @apply flex items-center h-[88px] border-t border-t-[#E1E4EA] space-x-3 mx-5;

    .app-menu-footer-logo {
      @apply w-10 h-10 bg-blue-200 rounded-full;
    }

    .app-menu-footer-content {
      @apply flex flex-col;

      .profile-name {
        @apply text-[13px] font-medium;
      }
      .profile-email {
        font-size: 11px;
      }
    }
  }
}
