.auth-container {
  display: flex;
  background-color: #f5f7fb;
  height: 100vh;
}

.login-container {
  .logo-content {
    background: #001e26;
    width: 100%;
    padding: 15px 25px;
    color: #fff;
  }

  .login-button {
    @apply bg-[#3b82f6] text-white;
    @apply hover:bg-[#3378e6] text-white;
    text-transform: inherit;
  }

  input {
    @apply text-sm;
  }
}

.auth-kod-box {
  background: #ffffff;
  margin: 10px 0px 20px;
  border-radius: 6px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1),
    0px 1px 10px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
